import React from 'react'
import Layout from '../components/layout'
import { graphql } from "gatsby"
import { NamespacesConsumer } from 'react-i18next'
import { withI18next } from '@sckit/gatsby-plugin-i18next'

const NotFoundPage = () => (
    <NamespacesConsumer>
    {(t, { i18n }) => {
        return (
            <Layout>
                <h1>NOT FOUND</h1>
                <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </Layout>
        )
    }}
    </NamespacesConsumer>
)

export default withI18next()(NotFoundPage)

export const query = graphql`
query NotFoundLng($lng: String!) {
    locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
        ...LocaleFragment
    }
}
`
